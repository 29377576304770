import React, {useState, useEffect} from 'react'
import { Link } from 'react-router-dom'
import {Form, Row, Col, Button} from 'react-bootstrap'
 
//import { useDispatch, useSelector } from 'react-redux'
//import Message from '../components/Message'
//import Loader from '../components/Loader'
//import { login } from '../actions/userActions'
import FormContainer from '../components/FormContainer'
import axios from 'axios'

const LoginScreen = ( { match, location, history } ) => {

  const [userName, setUsername] = useState('')
  const [password, setPassword] = useState('')


  console.log(match.params.docid)
  //const dispatch = useDispatch()
  //const userLogin = useSelector(state => state.userLogin)
  //const { loading, error, userInfo } = userLogin


  const submitHandler = (e) => {
    e.preventDefault()
    
    axios.post('/api/login', {
      userName: userName,
      password: password,
      docid: sessionStorage.getItem('docid')
    })
      .then(function (response) {
        console.log('U', response.data.token)
        //localStorage.setItem('userInfo')
        sessionStorage.setItem('userToken', response.data.token)
        window.location.replace("/");
        //history.push('/')
      })
  }

  return (
    <FormContainer>
      {sessionStorage.setItem('docid', match.params.docid)}
      <h1>Log på </h1>

      <Form onSubmit={submitHandler}>
        <Form.Group controlId='userName'>
          <Form.Label>Brugernavn</Form.Label>
          <Form.Control type='userName' placeholder='Skriv dit brugernavn' value={userName} onChange={(e) => setUsername(e.target.value)}>
          </Form.Control>
        </Form.Group>
      
  
      <Form.Group controlId='password'>
        <Form.Label>Password</Form.Label>
        <Form.Control type='password' placeholder='Enter password' value={password} onChange={(e) => setPassword(e.target.value)}>
        </Form.Control>
      </Form.Group>

      <Button type='submit' variant='primary'>
       Log på
      </Button>
      </Form>
    </FormContainer>
  )
}

export default LoginScreen

