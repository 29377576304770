import React from 'react'
import { Card, Badge } from 'react-bootstrap'

const JobStory = ({ job }) => {
  const jobperformers = job.jobperformer.split(',')
  console.log(jobperformers.length)

  return (
    <Card className='my-3 p-3 h-75 rounded'>
    <Card.Body>
      <a href={`/job/${job._id}`}>
        <Card.Title as='div'>
            
              {job.category !== 'main' ? 
              (<strong className='job-title'>
                <Badge variant='primary'>{job.category}</Badge><br />
                {job.name}
              </strong>) : ( 
              <strong className='job-title'>
              {job.name}
              </strong>)
              }

              
        </Card.Title>        
      </a>
        <Card.Text as='div'>
          {jobperformers.map((jobperformer) => (
            <div key={jobperformer} className=''>{jobperformer}</div>
        ))}  
        
      </Card.Text>
    </Card.Body>      
  </Card>
 )
}

export default JobStory
