    import React, { useState, useEffect } from 'react'
//import products from '../products'
import axios from 'axios'
import { Row, Col, DropdownButton, Dropdown } from 'react-bootstrap'
import { Link } from 'react-router-dom';
import JobStory from '../components/JobStory'

const JobAtlasScreen = () => {
  
  const [jobs, setJobs] = useState([])
  useEffect(() => {
    const fetchJobs = async () => {
      //const { data } = await axios.get('/api/file/jobs/')
      const userToken = sessionStorage.getItem('userToken')
      const docid = sessionStorage.getItem('docid')
      const config = {
        headers: {
          Authorization: `Bearer ${userToken}`,
          docid
        },
      }

      const { data } = await axios.get('/api/file/jobs/', config)
      setJobs(data)
    }
    fetchJobs()
  }, [])


  const jobperformersAll = []
  let jobPerformerTemp = []
  
  jobs.forEach((job, index) => {
    jobPerformerTemp = job.jobperformer.split(',')

    jobPerformerTemp.map((jobPerformer) => (
      jobperformersAll.push(jobPerformer)
    ))
  })

  const jobPerformers = [...new Set(jobperformersAll)];


  const handleSelectJobPerformer=(e)=>{
    console.log(e);
    //setProductCategory(e)
  }

  return (
    <>
      <h1>Oversigt over jobs</h1>

      <DropdownButton id="dropdown-category" title="Vælg Jobperformer" onSelect={handleSelectJobPerformer}>
        {jobPerformers.map((jobPerformer, index) => {
          const link =  `/jobperformer/${jobPerformer}`.toLowerCase()
            return (
              <Dropdown.Item key={index} as={Link} to={link}>{jobPerformer}</Dropdown.Item>
            )
        })}
    </DropdownButton>

       <Row>
        {jobs.map((job, index) => (
          job.category === 'main' && (
          <Col key={index} sm={12} md={6} lg={4} xl={3}>           
            <JobStory job={job} />
          </Col>
        )
        ))}
      </Row> 
    </>
  )
}

export default JobAtlasScreen
