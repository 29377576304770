import React, { useState, useEffect } from 'react'
//import products from '../products'
import axios from 'axios'
import { Row, Col } from 'react-bootstrap'

const LogoutScreen = () => {
  const userToken = sessionStorage.getItem('userToken')
  const docid = sessionStorage.getItem('docid') 
      const config = {
        headers: {
          Authorization: `Bearer ${userToken}`,
          docid
        },
      }

      axios.get('/api/deletecookies', config)
  sessionStorage.removeItem('userToken')
  
  window.location.replace("/login/" + docid);


 


  return (
    <>
      <h1>Du er nu logget ud</h1>
       <Row>
          <Col sm={12} md={6} lg={4} xl={3}>           
            <p>Tak for besøget! Du er nu logget ud</p>
          </Col>
      </Row> 
    </>
  )
}

export default LogoutScreen
