import React, { useState, useEffect } from 'react'
//import products from '../products'
import axios from 'axios'
import { Row, Col, Badge } from 'react-bootstrap'
import JobStory from '../components/JobStory'


const JobPerformerScreen = ({ match }) => {
  
  const jobPerformer = match.params.jobperformer
  console.log(match.params)
  console.log(jobPerformer)

  const [jobs, setJobs] = useState([])
  useEffect(() => {
    const fetchJobs = async () => {

      const userToken = sessionStorage.getItem('userToken')
      const docid = sessionStorage.getItem('docid')
      

      console.log(userToken)
      const config = {
        headers: {
          Authorization: `Bearer ${userToken}`,
          docid
        },
      }

      const jobPerformerFilter = `/api/file/jobs/${match.params.jobperformer}`
      console.log('jobPerformerFilter', jobPerformerFilter)   
      const { data } = await axios.get('/api/file/jobs/', config)
      
      setJobs(data)

    }
    fetchJobs()
  }, [match])


  //let jobPerformerJobs = jobs.filter(job => job.jobperformer.toLowerCase() == jobPerformer);
  let jobPerformerJobs = jobs.filter(job => job.jobperformer.toLowerCase().includes(jobPerformer) );

  return (
    <>
      <h1>Jobperformer: {jobPerformer}</h1>
      <p>{jobPerformerJobs.length} jobs</p>
       <Row>
        {jobPerformerJobs.map((job, index) => (
        
          job.category === 'main' ? (
            <Col key={index} sm={12} md={12} lg={12} xl={12}>
              <div className='my-3'>
                <h3>
                  <Badge className='my-3' variant='primary'>Job story <br /></Badge><br />
                  {job.name}
                </h3>
                <p>{job.description !== null && job.description}</p>
              </div>
            </Col>
          ) : ( 
          <Col key={index} sm={12} md={6} lg={4} xl={3}>           
              <JobStory job={job} />
          </Col>
        )
        ))}
      </Row> 
    </>
  )
}

export default JobPerformerScreen
