import { Container } from 'react-bootstrap'
import { BrowserRouter as Router, Route } from 'react-router-dom'
import Header from './components/Header'
import Footer from './components/Footer'
import JobAtlasScreen from './screens/JobAtlasScreen'
import JobScreen from './screens/JobScreen'
import JobPerformerScreen from './screens/JobPerformerScreen'
import LoginScreen from './screens/LoginScreen'
import LogoutScreen from './screens/LogoutScreen2'
import ShowPdfScreen from './screens/ShowPdfScreen'


const App = () => {
  return (
    <Router>
      <Header />
      <main className='py-3'>
        <Container>
          <Route path='/job/:id' component={JobScreen} />
          <Route path='/jobperformer/:jobperformer' component={JobPerformerScreen} />
          <Route path='/login/:docid' component={LoginScreen} />
          <Route path='/logout' component={LogoutScreen} />
          <Route path='/pdf' component={ShowPdfScreen} />
          <Route path='/' component={JobAtlasScreen} exact />
        </Container>
        
      </main>
      <Footer />
    </Router>
  )
}

export default App
