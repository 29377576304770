import React from 'react'
import { Navbar, Nav, Container } from 'react-bootstrap'
 let isLoggedin
 sessionStorage.getItem('userToken') ? isLoggedin = true : isLoggedin = false
 const docid = sessionStorage.getItem('docid') 
const Header = () => {

  return (
    <header>
      <Navbar bg="dark" variant="dark" expand="lg" collapseOnSelect>
        <Container>
          <Navbar.Brand href="/">JTBD</Navbar.Brand>
          <Navbar.Toggle aria-controls="basic-navbar-nav" />
          <Navbar.Collapse id="basic-navbar-nav">
            <Nav className="ml-auto">
            {isLoggedin && (<Nav.Link href="/"><i className='fas fa-tasks'></i> Job-atlas</Nav.Link>)}
              <Nav.Link href="/about"><i className='fas fa-question'></i> Om Jobs To Be Done</Nav.Link>
              {isLoggedin && (<Nav.Link href="/pdf"><i className='far fa-file-pdf'></i> Workshop-pdf</Nav.Link>)}
              {isLoggedin && (<Nav.Link href="/logout"><i className='fas fa-user'></i> Logout</Nav.Link>)}
              {!isLoggedin && (<Nav.Link href={`/login/${docid}`}><i className='fas fa-user'></i> Login</Nav.Link>)}
            </Nav>

          </Navbar.Collapse>
        </Container>
    </Navbar>
    </header>
  )
}

export default Header
